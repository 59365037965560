<template>
  <div>
    <div class="info" id="watermark">
      <van-popup v-model="illDateShow" position="bottom">
        <van-datetime-picker
          v-model="illDate"
          type="datetime"
          title="请选择"
          :formatter="formatDate"
          @cancel="timeCancel"
          @confirm="timeConfirm"
        />
      </van-popup>
      <van-popup v-model="subareaShow" position="bottom" >
        <van-picker title="请选择" show-toolbar :columns="subareaList" value-key="name"
                    @confirm="subareaConfirm" @cancel="subareaShow = false"/>
      </van-popup>
      <van-cell-group>
        <van-cell class="custom">
          <template #title>
            <span class="custom-title">车辆违停</span>
          </template>
        </van-cell>
        <van-cell title="所在小区" :value="subareaStr" @click="subareaShow = edit" :clickable="edit"
                        :value-class="{'value-common': subareaStr == '请选择'}" :is-link="edit" />
        <van-cell title="违停时间" :value="submitData.illDate" @click="illDateShow = edit" :clickable="edit"
                  :value-class="{'value-common': submitData.illDate == '请选择'}" :is-link="edit" />

        <van-field v-model="submitData.remark" rows="4" class="left" :border="false" autosize type="textarea" placeholder="请输入违停描述" :readonly="!edit" />
        <van-row>
          <van-col :span="24">
            <div class="upload" style="margin-left:15px">
              <upload-file
                :file-list="fileList"
                :del-btn-show="edit"
                :upload-btn-show="edit"
                @filesUpload="filesUpload"
                :upload-path="uploadPath"
                :file-type="'image'"/>
            </div>
          </van-col>
        </van-row>
      </van-cell-group>
      <van-row class="btns" v-if="edit">
        <van-col :span="24">
          <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import {formatterDateTimeWithOutSec} from "@/utils/utils"
import uploadFile from '@/components/upload/uploadFile'
import {getImageStream} from '@/utils/index'
export default {
  data() {
    return {
      edit: true,
      loading: false,
      illDateShow: false,
      subareaShow: false,
      uploadPath: 'files/callIllPark/temporary',
      illDate: '',
      subareaStr: '请选择',
      submitData: {
        id: 0,
        carId: '',
        userId: '',
        subarea: '',
        newFileMessages: [],
        illDate: '请选择',
        remark: ''
      },
      subareaList: [],
      fileList: []
    }
  },
  components: {
    topBar,
    uploadFile
  },
  methods: {
    getSubareaList () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.subareaList = data.subAreaList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    subareaConfirm (value) {
      if (value) {
        this.subareaStr = value.name
        this.submitData.subarea = value.id
      }
      this.subareaShow = false
    },
    formatDate (type, val) {
      if (type === 'year') {
          return val + '年'
      }
      if (type === 'month') {
          return val + '月'
      }
      if (type === 'day') {
          return val + '日'
      }
      if (type === 'hour') {
          return val + '时'
      }
      if (type === 'minute') {
          return val + '分'
      }
      return val
    },
    timeCancel() {
      this.illDateShow = false;
    },
    timeConfirm(value) {
      if (value) {
        this.submitData.illDate = formatterDateTimeWithOutSec(value)
        this.illDate = value
      }
      this.illDateShow = false
    },
    // 初始化
    init() {
      let nowDate = new Date()
      this.maxDate = nowDate
      this.illDate = nowDate
      this.submitData.illDate = formatterDateTimeWithOutSec(nowDate)
    },
    filesUpload (files) {
      this.submitData.newFileMessages = files
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/device/car/mistake/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.submitData.id
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.subareaStr = data.deviceCarMistake.subareaStr
          this.submitData.subarea = data.deviceCarMistake.subarea
          this.submitData.illDate = data.deviceCarMistake.mistakeTime
          this.submitData.remark = data.deviceCarMistake.content
          if (data.deviceCarMistake.deviceCarMistakeFileList && data.deviceCarMistake.deviceCarMistakeFileList.length != 0) {
            data.deviceCarMistake.deviceCarMistakeFileList.forEach(item=> {
              let u;
                u = getImageStream(item.filePath)
                let url = {
                  uid: this.fileList.length,
                  id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                  status: 'done',//uploading上传中，done上传完成，error上传失败
                  fileType: 1,
                  url: u,
                  relativePath: item.filePath
                }
                this.fileList.push(url);
            })
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 提交
    submit() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/device/car/mistake/save'),
        method: 'post',
        data: this.$http.adornData({
          carId: this.submitData.carId,
          subarea: this.submitData.subarea,
          userId: this.submitData.userId,
          mistakeTime: this.submitData.illDate,
          content: this.submitData.remark,
          newFileMessages: this.submitData.newFileMessages
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$toast.success ({
            message: '新增成功',
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
          this.loading = false
        } else {
          this.$toast.fail(data.msg)
        }
      })
    }
  },
  created() {
    this.submitData.id = this.$route.query.id || ''
    this.submitData.carId = this.$route.query.carId || ''
    this.submitData.subarea = this.$route.query.subarea || ''
    this.subareaStr = this.$route.query.subareaStr
    this.submitData.userId = this.$route.query.userId || ''
    document.querySelector('html').style.backgroundColor = '#fff'
    this.getSubareaList()
    this.init()
    if (this.submitData.id) {
      this.getInfo()
      this.edit = false
    }
  },
  beforeDestroy() {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
